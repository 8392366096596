.mix-monitor-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #121212;
  color: white;
  font-family: 'Roboto', 'Segoe UI', sans-serif;
}

.mix-monitor-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background: linear-gradient(90deg, rgba(18, 18, 18, 0.95) 0%, rgba(32, 32, 32, 0.95) 100%);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.header-content {
  display: flex;
  flex-direction: column;
}

.header-content h2 {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.tech-icon {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.header-icon {
  width: 24px;
  height: 24px;
  filter: drop-shadow(0 0 3px rgba(255, 255, 255, 0.5));
}

.tech-badge {
  font-size: 0.7rem;
  background-color: rgba(76, 175, 80, 0.2);
  color: #4caf50;
  padding: 2px 6px;
  border-radius: 10px;
  margin-left: 8px;
  font-weight: 600;
  letter-spacing: 0.5px;
  border: 1px solid rgba(76, 175, 80, 0.3);
}

.header-subtitle {
  margin: 5px 0 0 0;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
}

.status-counters {
  display: flex;
  gap: 15px;
}

.online-counter,
.offline-counter {
  padding: 8px 15px;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.online-counter {
  background: linear-gradient(135deg, rgba(76, 175, 80, 0.2), rgba(76, 175, 80, 0.1));
  color: #4caf50;
  border: 1px solid rgba(76, 175, 80, 0.3);
}

.online-counter:hover {
  background: linear-gradient(135deg, rgba(76, 175, 80, 0.3), rgba(76, 175, 80, 0.2));
  transform: translateY(-2px);
}

.online-counter::before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #4caf50;
  margin-right: 8px;
  box-shadow: 0 0 5px #4caf50;
  animation: pulse-green 2s infinite;
}

@keyframes pulse-green {
  0% {
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.7);
  }
  70% {
    box-shadow: 0 0 0 5px rgba(76, 175, 80, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
  }
}

.offline-counter {
  background: linear-gradient(135deg, rgba(244, 67, 54, 0.2), rgba(244, 67, 54, 0.1));
  color: #f44336;
  border: 1px solid rgba(244, 67, 54, 0.3);
}

.offline-counter:hover {
  background: linear-gradient(135deg, rgba(244, 67, 54, 0.3), rgba(244, 67, 54, 0.2));
  transform: translateY(-2px);
}

.offline-counter::before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #f44336;
  margin-right: 8px;
  box-shadow: 0 0 5px #f44336;
}

.map-container {
  flex: 1;
  width: 100%;
  position: relative;
}

.mix-monitor-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background: linear-gradient(90deg, rgba(18, 18, 18, 0.95) 0%, rgba(32, 32, 32, 0.95) 100%);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
}

.update-button-main,
.back-button {
  padding: 10px 20px;
  border-radius: 50px;
  border: none;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  min-width: 150px;
  letter-spacing: 0.5px;
}

.update-button-main {
  background: linear-gradient(135deg, #4caf50, #2e7d32);
  color: white;
  position: relative;
  overflow: hidden;
}

.update-button-main::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  transform: rotate(45deg);
  transition: all 0.3s ease;
  opacity: 0;
}

.update-button-main:hover::after {
  opacity: 1;
  transform: rotate(45deg) translate(50%, 50%);
}

.update-button-main:hover {
  background: linear-gradient(135deg, #2e7d32, #1b5e20);
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.update-button-main:disabled {
  background: linear-gradient(135deg, #9e9e9e, #757575);
  cursor: not-allowed;
  transform: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.back-button {
  background: linear-gradient(135deg, #424242, #212121);
  color: white;
  position: relative;
  overflow: hidden;
}

.back-button::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  transform: rotate(45deg);
  transition: all 0.3s ease;
  opacity: 0;
}

.back-button:hover::after {
  opacity: 1;
  transform: rotate(45deg) translate(50%, 50%);
}

.back-button:hover {
  background: linear-gradient(135deg, #212121, #000000);
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.button-icon {
  margin-right: 8px;
  font-size: 16px;
}

.map-legend {
  display: none;
}

.loading-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(18, 18, 18, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(3px);
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  border-top: 3px solid #4caf50;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(76, 175, 80, 0.5);
}

.loading-container p {
  color: white;
  font-size: 16px;
  font-weight: 500;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.error-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(135deg, #424242, #212121);
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  text-align: center;
  z-index: 1000;
  color: white;
  max-width: 400px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.error-container p {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.5;
}

.error-container button {
  background: linear-gradient(135deg, #4caf50, #2e7d32);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  letter-spacing: 0.5px;
}

.error-container button:hover {
  background: linear-gradient(135deg, #2e7d32, #1b5e20);
  transform: translateY(-2px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .mix-monitor-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding: 10px 15px;
  }

  .status-counters {
    width: 100%;
    justify-content: space-between;
  }

  .mix-monitor-footer {
    padding: 10px 15px;
  }

  .update-button-main,
  .back-button {
    padding: 8px 15px;
    font-size: 13px;
    min-width: 120px;
  }
}

@media (max-width: 480px) {
  .header-content h2 {
    font-size: 1.2rem;
  }

  .header-subtitle {
    font-size: 0.8rem;
  }

  .online-counter,
  .offline-counter {
    padding: 6px 12px;
    font-size: 12px;
  }

  .update-button-main,
  .back-button {
    min-width: auto;
    padding: 8px 12px;
  }
}

.info-window {
  padding: 20px;
  font-family: 'Roboto', sans-serif;
  color: #e0e0e0;
  max-width: 320px;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.4);
  background: linear-gradient(145deg, #1e1e1e, #121212);
  border: 1px solid rgba(255, 255, 255, 0.05);
  position: relative;
  overflow: hidden;
}

.info-window::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(90deg, #4caf50, #8bc34a);
  box-shadow: 0 0 10px rgba(76, 175, 80, 0.5);
}

.info-window h3 {
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 18px;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-weight: 600;
  letter-spacing: -0.3px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.station-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.3)) brightness(1.2);
}

.info-window p {
  margin: 8px 0;
  font-size: 14px;
  color: #b0b0b0;
  font-weight: 400;
  line-height: 1.4;
}

/* Estilo para o indicador de transmissão ao vivo */
.live-status {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px auto;
  background: rgba(244, 67, 54, 0.15);
  padding: 8px 14px;
  border-radius: 50px;
  border: 1px solid rgba(244, 67, 54, 0.3);
  width: fit-content;
  box-shadow: 0 2px 8px rgba(244, 67, 54, 0.2);
}

.live-indicator {
  width: 10px;
  height: 10px;
  background-color: #f44336;
  border-radius: 50%;
  margin-right: 10px;
  animation: live-pulse 1.5s infinite;
  box-shadow: 0 0 8px rgba(244, 67, 54, 0.8);
}

.live-text {
  font-size: 13px;
  font-weight: 700;
  color: #ff5252;
  text-transform: uppercase;
  letter-spacing: 0.8px;
}

@keyframes live-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(244, 67, 54, 0.7);
  }
  70% {
    box-shadow: 0 0 0 5px rgba(244, 67, 54, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(244, 67, 54, 0);
  }
}

.player-container {
  margin-top: 18px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 18px;
}

/* Novo player com medidores de áudio */
.audio-player {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 15px;
  margin-top: 10px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
}

.station-info {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.station-logo {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  margin-right: 12px;
  background: linear-gradient(135deg, #4caf50, #2e7d32);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}

.station-logo img {
  width: 24px;
  height: 24px;
  filter: brightness(1.2);
}

.station-details {
  flex: 1;
}

.station-name {
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  margin: 0 0 4px 0;
}

.station-frequency {
  font-size: 12px;
  color: #b0b0b0;
  margin: 0;
}

/* Medidores de nível de áudio */
.audio-meters {
  display: flex;
  height: 50px;
  gap: 2px;
  margin: 15px 0;
  align-items: flex-end;
  padding: 0 5px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 6px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.05);
  padding: 5px;
  position: relative;
}

.meter-bar {
  flex: 1;
  background: rgba(76, 175, 80, 0.1);
  border-radius: 2px;
  height: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}

.meter-level {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(to top, #4caf50, #8bc34a);
  border-radius: 2px;
  box-shadow: 0 0 8px rgba(76, 175, 80, 0.4);
  height: 30%; /* Altura inicial padrão */
}

/* Animação contínua para os medidores */
@keyframes meter-continuous {
  0% {
    height: 30%;
  }
  10% {
    height: 90%;
  }
  20% {
    height: 40%;
  }
  30% {
    height: 70%;
  }
  40% {
    height: 20%;
  }
  50% {
    height: 60%;
  }
  60% {
    height: 35%;
  }
  70% {
    height: 85%;
  }
  80% {
    height: 45%;
  }
  90% {
    height: 75%;
  }
  100% {
    height: 30%;
  }
}

/* Aplicar animação contínua para cada barra com delays diferentes */
.audio-meters .meter-bar:nth-child(1) .meter-level {
  animation: meter-continuous 2.1s infinite ease-in-out;
}
.audio-meters .meter-bar:nth-child(2) .meter-level {
  animation: meter-continuous 2.3s infinite ease-in-out;
  animation-delay: -0.1s;
}
.audio-meters .meter-bar:nth-child(3) .meter-level {
  animation: meter-continuous 1.9s infinite ease-in-out;
  animation-delay: -0.2s;
}
.audio-meters .meter-bar:nth-child(4) .meter-level {
  animation: meter-continuous 2.2s infinite ease-in-out;
  animation-delay: -0.3s;
}
.audio-meters .meter-bar:nth-child(5) .meter-level {
  animation: meter-continuous 2s infinite ease-in-out;
  animation-delay: -0.4s;
}
.audio-meters .meter-bar:nth-child(6) .meter-level {
  animation: meter-continuous 1.8s infinite ease-in-out;
  animation-delay: -0.5s;
}
.audio-meters .meter-bar:nth-child(7) .meter-level {
  animation: meter-continuous 2.4s infinite ease-in-out;
  animation-delay: -0.6s;
}
.audio-meters .meter-bar:nth-child(8) .meter-level {
  animation: meter-continuous 2.2s infinite ease-in-out;
  animation-delay: -0.7s;
}
.audio-meters .meter-bar:nth-child(9) .meter-level {
  animation: meter-continuous 1.7s infinite ease-in-out;
  animation-delay: -0.8s;
}
.audio-meters .meter-bar:nth-child(10) .meter-level {
  animation: meter-continuous 2.5s infinite ease-in-out;
  animation-delay: -0.9s;
}
.audio-meters .meter-bar:nth-child(11) .meter-level {
  animation: meter-continuous 2s infinite ease-in-out;
  animation-delay: -1s;
}
.audio-meters .meter-bar:nth-child(12) .meter-level {
  animation: meter-continuous 1.9s infinite ease-in-out;
  animation-delay: -1.1s;
}
.audio-meters .meter-bar:nth-child(13) .meter-level {
  animation: meter-continuous 2.3s infinite ease-in-out;
  animation-delay: -1.2s;
}
.audio-meters .meter-bar:nth-child(14) .meter-level {
  animation: meter-continuous 2.1s infinite ease-in-out;
  animation-delay: -1.3s;
}
.audio-meters .meter-bar:nth-child(15) .meter-level {
  animation: meter-continuous 1.8s infinite ease-in-out;
  animation-delay: -1.4s;
}

/* Cores diferentes para níveis altos */
.meter-level.high {
  background: linear-gradient(to top, #ff9800, #ffeb3b);
  box-shadow: 0 0 8px rgba(255, 152, 0, 0.5);
}

.meter-level.peak {
  background: linear-gradient(to top, #f44336, #ff5722);
  box-shadow: 0 0 10px rgba(244, 67, 54, 0.6);
}

/* Efeito de brilho para simular batidas de áudio */
.audio-meters::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 25%;
  height: 1px;
  background: rgba(255, 255, 255, 0.1);
  z-index: 1;
}

.audio-meters::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  height: 1px;
  background: rgba(255, 255, 255, 0.1);
  z-index: 1;
}

/* Efeito de brilho pulsante */
.audio-glow {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 6px;
  box-shadow: inset 0 0 15px rgba(76, 175, 80, 0.3);
  animation: glow-pulse 2s infinite ease-in-out;
  pointer-events: none;
  z-index: 2;
}

@keyframes glow-pulse {
  0%,
  100% {
    box-shadow: inset 0 0 15px rgba(76, 175, 80, 0.2);
  }
  50% {
    box-shadow: inset 0 0 25px rgba(76, 175, 80, 0.5);
  }
}

/* Controles do player */
.player-controls {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 10px;
}

.player-button {
  padding: 12px;
  border-radius: 50%;
  border: none;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  width: 48px;
  height: 48px;
}

.play-button {
  background: linear-gradient(135deg, #4caf50, #2e7d32);
  color: white;
}

.play-button:hover {
  background: linear-gradient(135deg, #2e7d32, #1b5e20);
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
}

.stop-button {
  background: linear-gradient(135deg, #ff8c00, #e65100);
  color: white;
}

.stop-button:hover {
  background: linear-gradient(135deg, #e65100, #bf360c);
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
}

.player-status {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  color: #4caf50;
  font-weight: 500;
}

.playing-indicator {
  display: flex;
  align-items: center;
  gap: 4px;
}

.playing-dot {
  width: 4px;
  height: 4px;
  background-color: #4caf50;
  border-radius: 50%;
  animation: pulse 1.5s infinite ease-in-out;
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.6);
}

.playing-dot:nth-child(2) {
  animation-delay: 0.2s;
}

.playing-dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.5);
    opacity: 1;
  }
}

.player-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 14px;
  color: #b0b0b0;
  margin: 15px 0;
  padding: 10px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.loading-spinner-small {
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-top: 2px solid #4caf50;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.stream-unavailable {
  color: #ff5252;
  font-size: 14px;
  text-align: center;
  margin: 15px 0;
  font-weight: 500;
  padding: 12px;
  background: rgba(244, 67, 54, 0.1);
  border-radius: 8px;
  border: 1px solid rgba(244, 67, 54, 0.2);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Volume control */
.volume-control {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
  padding: 10px 0;
}

.volume-icon {
  color: #b0b0b0;
  font-size: 18px;
}

.volume-slider {
  flex: 1;
  height: 4px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  position: relative;
}

.volume-level {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 70%;
  background: linear-gradient(to right, #4caf50, #8bc34a);
  border-radius: 2px;
}

.volume-knob {
  position: absolute;
  top: 50%;
  left: 70%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  background: #ffffff;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

/* Estilos para o player móvel */
.mobile-player {
  width: 100%;
  margin: 10px 0;
  display: flex;
  justify-content: center;
}

.mobile-player audio {
  width: 100%;
  max-width: 250px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.1);
}

.mobile-player-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.mobile-stop-button {
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.mobile-playing-status {
  font-size: 14px;
  font-weight: bold;
  color: #4caf50;
  display: flex;
  align-items: center;
}

.mobile-playing-status::before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #4caf50;
  border-radius: 50%;
  margin-right: 8px;
  animation: pulse 1.5s infinite;
}

/* Responsividade para dispositivos móveis */
@media (max-width: 768px) {
  .info-window {
    max-width: 280px;
    padding: 10px;
  }

  .info-window h3 {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .info-window p {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .station-icon {
    width: 20px;
    height: 20px;
  }

  .live-status {
    padding: 3px 8px;
    margin: 5px 0;
  }

  .player-container {
    margin-top: 5px;
  }
}
