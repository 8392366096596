.roadmap-container {
  width: 100%;
  min-height: 100vh;
  padding: 20px;
  background-color: #f8f9fa !important;
  color: #333 !important;
  box-sizing: border-box;
  animation: fadeIn 0.6s ease-out forwards;
  position: relative;
  overflow: auto;
  z-index: 1;
  max-width: 1200px;
  margin: 0 auto;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeIn 0.8s ease forwards;
  height: calc(100vh - 180px);
  overflow-y: auto;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.roadmap-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid rgba(255, 107, 0, 0.2);
}

.roadmap-header h2 {
  margin: 0;
  color: #333;
  font-size: 1.8rem;
  text-align: center;
  font-weight: 700;
}

.roadmap-header .highlight {
  color: #FF6B00;
  background: linear-gradient(120deg, rgba(255, 107, 0, 0.15) 0%, rgba(255, 107, 0, 0) 100%);
  padding: 2px 10px;
  border-radius: 20px;
}

.back-button {
  background-color: #FF6B00;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(255, 107, 0, 0.25);
}

.back-button:hover {
  background-color: #e05f00;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(255, 107, 0, 0.3);
}

.back-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 5px rgba(255, 107, 0, 0.2);
}

.back-icon {
  margin-right: 8px;
  font-size: 18px;
}

.roadmap-content {
  position: relative;
  z-index: 2;
  background-color: #f8f9fa;
}

.roadmap-intro {
  text-align: center;
  margin-bottom: 2.5rem;
  padding: 0 1rem;
}

.roadmap-intro h3 {
  font-size: 1.3rem;
  color: #333;
  margin-bottom: 1rem;
}

.roadmap-intro p {
  color: #666;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
}

.timeline-element-content {
  padding: 15px;
  transition: all 0.3s ease;
  height: 100%;
}

.timeline-element-header {
  margin-bottom: 15px;
}

.vertical-timeline-element-title {
  margin-top: 0;
  color: #333 !important;
  font-weight: 600;
}

.vertical-timeline-element-subtitle {
  margin: 5px 0 15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.status-indicator {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.status-icon {
  margin-right: 8px;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
}

.status-badge {
  display: inline-block;
  padding: 3px 8px;
  border-radius: 15px;
  color: white;
  font-size: 0.75rem;
  font-weight: 600;
}

.timeline-description {
  margin: 12px 0;
  color: #555;
  line-height: 1.5;
  font-size: 0.95rem;
}

.tech-preview {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin: 15px 0;
}

.tech-preview-tag {
  background-color: #f0f0f0;
  color: #555;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.75rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.tech-preview-tag:hover {
  background-color: #e0e0e0;
}

.tech-preview-more {
  color: #888;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
}

.details-button {
  background-color: #FF6B00;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 600;
  margin-top: 15px;
  transition: all 0.3s ease;
}

.details-button:hover {
  background-color: #e05f00;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(255, 107, 0, 0.3);
}

.ripple-effect {
  position: relative;
  overflow: hidden;
}

.ripple-effect:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform 0.4s, opacity 0.8s;
}

.ripple-effect:active:after {
  transform: scale(0, 0);
  opacity: 0.3;
  transition: 0s;
}

.spinning {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  padding: 20px 0;
}

.project-modal {
  position: relative;
  width: 90%;
  max-width: 700px;
  max-height: 90vh;
  overflow-y: auto;
  background-color: white;
  border-radius: 15px;
  padding: 0;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.modal-content {
  position: relative;
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  color: #666;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.modal-close:hover {
  color: #333;
  transform: scale(1.1);
}

.modal-header {
  padding: 30px 20px;
  background-color: #f0f0f0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
  min-height: 150px;
}

.modal-header-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.6) 100%);
  z-index: 1;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.modal-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
  z-index: 2;
  border: 3px solid white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-title {
  flex-grow: 1;
  z-index: 2;
  margin-left: 20px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8);
}

.modal-title h2 {
  margin: 0;
  font-size: 24px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 8px;
}

.modal-subtitle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  margin-top: 5px;
}

.modal-subtitle .status-badge {
  font-size: 0.8rem;
  padding: 4px 10px;
  margin-top: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.modal-period {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #fff;
  font-size: 0.9rem;
}

.modal-body {
  padding: 25px;
}

.modal-section {
  margin-bottom: 25px;
}

.modal-section h3 {
  margin: 0 0 15px 0;
  font-size: 1.1rem;
  color: #444;
  font-weight: 600;
}

.modal-section p {
  margin: 0;
  line-height: 1.6;
  color: #555;
  white-space: pre-line;
  word-wrap: break-word;
  max-width: 100%;
}

/* Estilo específico para o texto de descrição */
.description-text {
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 6px;
  border-left: 3px solid #ff6b00;
  overflow: auto;
  max-height: 600px;
}

/* Estilos para conteúdo rico na descrição */
.description-text img {
  max-width: 100%;
  height: auto;
  margin: 10px 0;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: block;
}

.description-text iframe {
  max-width: 100%;
  height: 250px;
  margin: 10px 0;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: block;
}

.description-text h1, 
.description-text h2, 
.description-text h3 {
  margin-top: 15px;
  margin-bottom: 10px;
  color: #333;
  font-weight: bold;
}

.description-text ul, 
.description-text ol {
  padding-left: 25px;
  margin: 10px 0;
}

.description-text li {
  margin-bottom: 5px;
}

.description-text p {
  margin-bottom: 10px;
  line-height: 1.6;
}

.description-text a {
  color: #ff6b00;
  text-decoration: underline;
}

/* Estilos para responsáveis */
.responsaveis-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.responsavel-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 12px;
  border-radius: 6px;
  margin-bottom: 4px;
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  position: relative;
}

.responsavel-item.responsavel-principal {
  background-color: #fff5eb;
  border-color: #ffdab3;
}

.responsavel-item.responsavel-secundario {
  margin-left: 20px;
}

.responsavel-icon {
  font-size: 1.2em;
  color: #ff6b00;
}

.responsavel-principal .responsavel-icon {
  font-size: 1.4em;
  color: #ff6b00;
}

.responsavel-secundario .responsavel-icon {
  color: #ff9966;
}

.responsavel-icon.convidado {
  color: #3b82f6;
}

.responsavel-info {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
}

.responsavel-nome {
  font-weight: 500;
  color: #333;
}

.responsavel-principal .responsavel-nome {
  font-weight: 600;
  color: #ff6b00;
}

.responsavel-secundario .responsavel-nome {
  color: #ff9966;
}

.responsavel-funcao {
  color: #666;
  font-size: 0.9em;
  margin-left: 8px;
  padding-left: 8px;
  border-left: 1px solid #ddd;
}

.responsavel-tag {
  font-size: 0.75em;
  color: #a8c4ff;
  font-style: italic;
  background-color: #f8faff;
  padding: 2px 8px;
  border-radius: 4px;
  border: 1px solid #e6f0ff;
}

.responsavel-item.convidado {
  background-color: #f8faff;
  border-color: #e6f0ff;
}

.responsavel-icon.convidado {
  color: #a8c4ff;
}

.responsavel-item.convidado .responsavel-nome,
.responsavel-item.convidado .responsavel-funcao {
  color: #7a9fff;
}

.responsavel-checkbox {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.8em;
  color: #666;
}

.responsavel-checkbox input[type="checkbox"] {
  margin: 0;
}

.responsavel-icon-secundario {
  color: #666;
  margin-right: 8px;
  font-size: 0.9em;
}

/* Estilos para o card da timeline */
.responsavel-principal-nome {
  font-weight: bold;
}

.responsavel-mais {
  margin-left: 5px;
  font-size: 0.8em;
  background-color: #eee;
  color: #666;
  padding: 1px 5px;
  border-radius: 10px;
  vertical-align: middle;
}

.modal-tags {
  margin-top: 5px;
}

.modal-footer {
  padding: 15px 25px 25px;
  display: flex;
  justify-content: flex-end;
}

.modal-close-btn {
  background-color: #FF6B00;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s ease;
}

.modal-close-btn:hover {
  background-color: #e05f00;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(255, 107, 0, 0.3);
}

.tech-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
}

.tech-tag {
  display: inline-flex;
  align-items: center;
  background-color: #f0f0f0;
  color: #555;
  padding: 4px 8px;
  border-radius: 5px;
  font-size: 0.75rem;
  font-weight: 500;
}

.vertical-timeline::before {
  background-color: #FF6B00 !important;
  width: 4px;
}

.vertical-timeline-element {
  position: relative;
  margin: 2em 0;
  background-color: #fff !important;
}

.vertical-timeline-element-content {
  background-color: #fff !important;
  color: #333 !important;
  box-shadow: 0 3px 0 #ddd;
}

.vertical-timeline-element-content-arrow {
  border-right: 7px solid #fff !important;
}

.vertical-timeline-element-icon {
  box-shadow: 0 0 0 4px white, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05) !important;
}

.vertical-timeline-element-content {
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1) !important;
  border-radius: 12px !important;
  padding: 25px !important;
}

.vertical-timeline-element-date {
  color: #FF6B00 !important;
  font-weight: 600 !important;
  opacity: 1 !important;
  font-size: 1.1rem !important;
  margin-top: 10px !important;
}

@media (max-width: 1170px) {
  .vertical-timeline-element-date {
    margin: 0.5em 0 1em !important;
  }
}

@media (max-width: 768px) {
  .roadmap-header {
    flex-direction: column;
    gap: 15px;
  }
  
  .roadmap-header h2 {
    font-size: 1.5rem;
  }
  
  .modal-header {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .modal-icon {
    margin-right: 0;
    margin-bottom: 15px;
  }
  
  .modal-subtitle {
    justify-content: center;
  }
  
  .project-modal {
    max-height: 80vh;
    width: 95%;
    margin: 10px auto;
  }
  
  .modal-body {
    padding: 15px;
  }
}

/* Estilos para o botão de atualização */
.refresh-button {
  background-color: transparent;
  border: none;
  color: #ff6b00;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.refresh-button:hover {
  background-color: rgba(255, 107, 0, 0.1);
  transform: rotate(30deg);
}

/* Animação de rotação para o spinner */
.spinning {
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Estado de carregamento */
.loading-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px auto;
  padding: 20px;
  text-align: center;
  color: #555;
}

.loading-state .spinning {
  font-size: 2rem;
  color: #ff6b00;
  margin-bottom: 15px;
}

/* Mensagem de erro da API */
.api-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px auto;
  padding: 20px;
  text-align: center;
  color: #d32f2f;
  border: 1px solid #ffcdd2;
  background-color: #fff5f5;
  border-radius: 8px;
  max-width: 500px;
}

.api-error svg {
  font-size: 2rem;
  margin-bottom: 15px;
}

.api-error button {
  margin-top: 15px;
  padding: 8px 16px;
  background-color: #ff6b00;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.api-error button:hover {
  background-color: #e65100;
}

/* Estilizar a barra de rolagem */
.roadmap-container::-webkit-scrollbar {
  width: 8px;
}

.roadmap-container::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.roadmap-container::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.roadmap-container::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3);
}

/* Ajustar espaçamento vertical no timeline */
.vertical-timeline-element {
  margin: 2em 0;
}

.vertical-timeline {
  padding-bottom: 50px;
}

/* Financials */
.financials {
  background-color: rgba(255, 107, 0, 0.05);
  border-radius: 8px;
  padding: 20px;
  border-left: 4px solid #FF6B00;
  margin-bottom: 20px;
}

.financial-item {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.financial-item strong {
  display: inline-block;
  width: 180px;
  color: #555;
}

.financial-item.roi {
  font-weight: bold;
  color: #4CAF50;
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px dashed #ccc;
}

/* Documentos */
.documentos-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;
}

.documento-link {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  background-color: #f0f0f0;
  border-radius: 6px;
  color: #333;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.documento-link:hover {
  background-color: #e0e0e0;
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Ajuste no tamanho do modal */
.project-modal {
  max-width: 800px;
  width: 90%;
  max-height: 90vh;
  margin: 20px auto;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.3);
  position: relative;
  padding: 0;
  overflow: auto;
  outline: none;
} 