.roadmap-admin-container {
  width: 100%;
  min-height: 100vh;
  background-color: #f8f9fa;
  padding: 20px;
  box-sizing: border-box;
  max-width: 1200px;
  margin: 0 auto;
  height: calc(100vh - 120px);
  overflow-y: auto;
}

/* Estilizar a barra de rolagem */
.roadmap-admin-container::-webkit-scrollbar {
  width: 8px;
}

.roadmap-admin-container::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.roadmap-admin-container::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.roadmap-admin-container::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3);
}

/* Cabeçalho */
.roadmap-admin-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 2px solid #ff6b00;
}

.roadmap-admin-header h2 {
  margin: 0;
  color: #333;
}

.admin-actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

.back-button,
.add-button,
.refresh-button {
  padding: 8px 15px;
  background-color: #ff6b00;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color 0.3s;
}

.back-button:hover,
.add-button:hover {
  background-color: #e65100;
}

.back-button {
  background-color: #333;
}

.back-button:hover {
  background-color: #555;
}

.refresh-button {
  background-color: transparent;
  color: #ff6b00;
  padding: 8px;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.refresh-button:hover {
  background-color: rgba(255, 107, 0, 0.1);
}

.refresh-button .spinning {
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Mensagens */
.message {
  padding: 10px 15px;
  margin-bottom: 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.message.success {
  background-color: #e8f5e9;
  color: #2e7d32;
  border-left: 4px solid #2e7d32;
}

.message.error {
  background-color: #ffebee;
  color: #c62828;
  border-left: 4px solid #c62828;
}

.message.warning {
  background-color: #fff8e1;
  color: #f57f17;
  border-left: 4px solid #f57f17;
}

/* Estado de carregamento */
.loading-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px auto;
  padding: 20px;
  text-align: center;
  color: #555;
}

.loading-state .spinning {
  font-size: 2rem;
  color: #ff6b00;
  margin-bottom: 15px;
}

/* Mensagem de erro da API */
.api-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px auto;
  padding: 20px;
  text-align: center;
  color: #d32f2f;
  border: 1px solid #ffcdd2;
  background-color: #fff5f5;
  border-radius: 8px;
  max-width: 500px;
}

.api-error svg {
  font-size: 2rem;
  margin-bottom: 15px;
}

.api-error button {
  margin-top: 15px;
  padding: 8px 16px;
  background-color: #ff6b00;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.api-error button:hover {
  background-color: #e65100;
}

/* Mensagem quando não há projetos */
.no-projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px auto;
  padding: 20px;
  text-align: center;
}

.no-projects button {
  margin-top: 15px;
  padding: 8px 16px;
  background-color: #ff6b00;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.no-projects button:hover {
  background-color: #e65100;
}

/* Formulário de edição */
.edit-form {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 12px;
  margin-bottom: 30px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  border: 1px solid #f0f0f0;
}

.edit-form h3 {
  margin-top: 0;
  margin-bottom: 25px;
  color: #333;
  border-bottom: 2px solid #ff6b00;
  padding-bottom: 15px;
  font-size: 1.5rem;
  text-align: center;
}

.form-row {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.form-group {
  flex: 1;
  margin-bottom: 20px;
  min-width: 200px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  color: #555;
  font-size: 0.9rem;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 0.95rem;
  transition: all 0.3s ease;
  background-color: #f9f9f9;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-color: #ff6b00;
  outline: none;
  box-shadow: 0 0 0 3px rgba(255, 107, 0, 0.1);
  background-color: #fff;
}

/* Estilo para textarea de descrição detalhada */
.form-group textarea {
  width: 100%;
  min-height: 150px;
  padding: 12px;
  line-height: 1.5;
  font-size: 14px;
  border-radius: 6px;
  resize: vertical;
  transition: border 0.3s, box-shadow 0.3s;
}

.form-group textarea:focus {
  border-color: #ff6b00;
  box-shadow: 0 0 0 2px rgba(255, 107, 0, 0.2);
  outline: none;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #f0f0f0;
}

.save-button,
.cancel-button {
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;
  font-size: 0.95rem;
}

.save-button {
  background-color: #ff6b00;
  color: white;
  box-shadow: 0 4px 12px rgba(255, 107, 0, 0.25);
}

.save-button:hover {
  background-color: #e05f00;
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(255, 107, 0, 0.3);
}

.cancel-button {
  background-color: #f0f0f0;
  color: #555;
}

.cancel-button:hover {
  background-color: #e0e0e0;
  transform: translateY(-2px);
}

/* Lista de Projetos */
.project-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 20px;
  margin-top: 20px;
  padding-bottom: 40px;
}

.project-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}

.project-header {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
  position: relative;
}

.project-icon {
  width: 40px;
  height: 40px;
  background-color: #ff6b00;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.project-title h3 {
  margin: 0 0 5px 0;
  font-size: 18px;
  color: #333;
}

.project-subtitle {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.project-period {
  font-size: 14px;
  color: #555;
}

.project-status {
  font-size: 12px;
  padding: 3px 8px;
  border-radius: 12px;
  color: white;
  background-color: #ff6b00;
}

.project-description {
  margin: 15px 0;
  color: #555;
  font-size: 14px;
}

.project-technologies {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 15px;
}

.tech-tag {
  font-size: 12px;
  padding: 4px 8px;
  background-color: #f0f0f0;
  color: #555;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.project-responsaveis {
  margin-bottom: 15px;
  font-size: 14px;
  color: #555;
}

.project-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 15px;
}

.edit-button,
.delete-button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 13px;
  transition: background-color 0.3s;
}

.edit-button {
  background-color: #2196f3;
  color: white;
}

.edit-button:hover {
  background-color: #1976d2;
}

.delete-button {
  background-color: #f44336;
  color: white;
}

.delete-button:hover {
  background-color: #d32f2f;
}

/* Responsividade */
@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
    gap: 0;
  }
  
  .project-list {
    grid-template-columns: 1fr;
  }
}

/* Área de upload de arquivos */
.file-upload-area {
  border: 2px dashed #cccccc;
  border-radius: 8px;
  padding: 25px;
  text-align: center;
  margin-bottom: 15px;
  transition: all 0.3s ease;
  background-color: #f9f9f9;
  position: relative;
  cursor: pointer;
}

.file-upload-area:hover {
  border-color: #ff6b00;
  background-color: #fff9f5;
}

.file-upload-area input[type="file"] {
  cursor: pointer;
  opacity: 0.01;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.file-upload-area::after {
  content: "Clique ou arraste arquivos aqui";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #666;
  pointer-events: none;
}

.file-upload-area.has-image::after {
  content: "Clique para trocar a imagem";
  background-color: rgba(0,0,0,0.5);
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s;
}

.file-upload-area.has-image:hover::after {
  opacity: 1;
}

.image-preview {
  margin: 0 auto 15px;
  width: 100%;
  height: 200px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  background-color: #f0f0f0;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.image-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.image-preview:hover img {
  transform: scale(1.05);
}

/* Lista de documentos */
.documentos-lista {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 15px;
  margin-top: 15px;
}

.documentos-lista h4 {
  margin-top: 0;
  margin-bottom: 15px;
  color: #333;
  font-size: 1rem;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 8px;
}

.documentos-lista ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.documento-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  margin-bottom: 8px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.05);
  transition: transform 0.2s ease;
}

.documento-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.documento-item a {
  color: #2196F3;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 8px;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.documento-item a:hover {
  text-decoration: underline;
}

.remove-documento {
  background-color: transparent;
  border: none;
  color: #f44336;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.remove-documento:hover {
  background-color: #ffebee;
  transform: scale(1.1);
}

/* Estilo para as sugestões de tecnologias */
.tecnologias-sugestoes {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

.tecnologia-chip {
  background-color: #f0f0f0;
  border: 1px solid #e0e0e0;
  border-radius: 16px;
  padding: 4px 12px;
  font-size: 0.85rem;
  color: #555;
  cursor: pointer;
  transition: all 0.2s ease;
}

.tecnologia-chip:hover {
  background-color: #ff6b00;
  color: white;
  border-color: #ff6b00;
  transform: translateY(-2px);
}

/* Display de ROI */
.roi-display {
  background-color: #f0f9ff;
  border: 1px solid #e0e0e0;
  border-left: 4px solid #2196F3;
  border-radius: 8px;
  padding: 12px;
  font-size: 1.1rem;
  font-weight: 600;
  color: #333;
  text-align: center;
  min-height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Melhorias na lista de itens do Roadmap */
.items-list {
  margin-top: 40px;
}

.items-list h3 {
  margin-bottom: 20px;
  color: #333;
  border-bottom: 2px solid #ff6b00;
  padding-bottom: 15px;
}

.add-button {
  background-color: #ff6b00;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 12px 20px;
  font-size: 0.95rem;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(255, 107, 0, 0.25);
}

.add-button:hover {
  background-color: #e05f00;
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(255, 107, 0, 0.3);
}

.no-items {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 30px;
  text-align: center;
  color: #555;
  font-style: italic;
  margin-top: 20px;
}

.items-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.roadmap-item {
  background-color: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  border: 1px solid #f0f0f0;
  display: flex;
  flex-direction: column;
}

.roadmap-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.item-header {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9;
  border-bottom: 1px solid #f0f0f0;
}

.item-header h4 {
  margin: 0;
  color: #333;
  font-size: 1.1rem;
  font-weight: 600;
}

.status-badge {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 4px 10px;
  border-radius: 16px;
  font-size: 0.8rem;
  font-weight: 600;
}

.status-badge[data-status="Planejado"] {
  background-color: #e3f2fd;
  color: #1976d2;
}

.status-badge[data-status="Em Progresso"] {
  background-color: #fff8e1;
  color: #ff8f00;
}

.status-badge[data-status="Concluído"] {
  background-color: #e8f5e9;
  color: #388e3c;
}

.item-period {
  padding: 10px 15px;
  background-color: #fafafa;
  font-size: 0.85rem;
  color: #666;
  display: flex;
  align-items: center;
  gap: 5px;
}

.item-image {
  width: 100%;
  height: 160px;
  overflow: hidden;
}

.item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.roadmap-item:hover .item-image img {
  transform: scale(1.05);
}

.item-description {
  padding: 15px;
  flex-grow: 1;
}

.item-description p {
  margin: 0;
  color: #666;
  font-size: 0.9rem;
  line-height: 1.5;
}

.item-actions {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  border-top: 1px solid #f0f0f0;
}

.edit-button,
.remove-button {
  flex: 1;
  padding: 8px 12px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 0.85rem;
  font-weight: 600;
  transition: all 0.2s ease;
}

.edit-button {
  background-color: #e3f2fd;
  color: #1976d2;
}

.edit-button:hover {
  background-color: #bbdefb;
}

.remove-button {
  background-color: #ffebee;
  color: #e53935;
}

.remove-button:hover {
  background-color: #ffcdd2;
}

/* Spinner para carregamento */
.spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Mensagens */
.message {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  font-weight: 500;
  animation: fadeIn 0.3s ease;
}

.message.success {
  background-color: #e8f5e9;
  color: #2e7d32;
  border-left: 4px solid #2e7d32;
}

.message.error {
  background-color: #ffebee;
  color: #c62828;
  border-left: 4px solid #c62828;
}

.message.warning {
  background-color: #fff8e1;
  color: #f57c00;
  border-left: 4px solid #f57c00;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Estilo para campo somente leitura */
.readonly-input {
  background-color: #f5f5f5;
  cursor: not-allowed;
  color: #666;
  border: 1px solid #ddd;
}

/* Estilo para dicas de formulário */
.form-tip {
  display: block;
  margin-top: 5px;
  color: #666;
  font-size: 0.85rem;
  font-style: italic;
}

.form-tip .tip-list {
  margin-top: 5px;
  padding-left: 20px;
  list-style-type: disc;
}

.form-tip .tip-list li {
  margin-bottom: 5px;
}

.form-tip .icon-example {
  display: inline-block;
  background-color: #f0f0f0;
  padding: 2px 5px;
  border-radius: 3px;
  font-style: normal;
  margin: 0 2px;
}

/* Estilo para o editor Quill */
.quill-editor-container {
  margin-bottom: 15px;
}

.ql-toolbar.ql-snow {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: #f8f8f8;
  border: 1px solid #ddd !important;
}

.ql-container.ql-snow {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  min-height: 200px;
  border: 1px solid #ddd !important;
  font-size: 14px;
}

.ql-editor {
  min-height: 200px !important;
  padding: 12px;
  font-family: inherit;
}

.ql-editor img {
  max-width: 100%;
  height: auto;
  margin: 10px 0;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.ql-editor iframe {
  max-width: 100%;
  height: 250px;
  margin: 10px 0;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.responsaveis-edit {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 25px;
  background-color: #f9f9fa;
}

.responsaveis-header {
  display: grid;
  grid-template-columns: 2.5fr 2fr 170px 120px;
  gap: 15px;
  padding: 0 15px 15px 15px;
  border-bottom: 1px solid #e9ecef;
  margin-bottom: 15px;
}

.responsaveis-header span {
  font-size: 12px;
  font-weight: 600;
  color: #6c757d;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.responsavel-edit-row {
  display: grid;
  grid-template-columns: 2.5fr 2fr 170px 120px;
  gap: 15px;
  align-items: center;
  background-color: #ffffff;
  padding: 18px 20px;
  border-radius: 6px;
  border: 1px solid #e9ecef;
  transition: all 0.2s ease;
  margin-bottom: 10px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.04);
}

.responsavel-edit-row:hover {
  border-color: #ced4da;
  box-shadow: 0 2px 5px rgba(0,0,0,0.06);
}

.responsavel-edit-row.convidado {
  background-color: #f0f7ff;
  border-color: #cce4ff;
}

.responsavel-edit-row input[type="text"] {
  padding: 12px 14px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
  transition: all 0.2s ease;
}

.responsavel-edit-row input[type="text"]:focus {
  border-color: #ff6b00;
  box-shadow: 0 0 0 3px rgba(255, 107, 0, 0.1);
  outline: none;
}

.responsavel-edit-row.convidado input[type="text"] {
  font-style: italic;
}

.responsavel-checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
  padding: 8px 4px;
  margin-right: 10px;
}

.responsavel-checkbox input[type="checkbox"] {
  width: 16px;
  height: 16px;
  margin: 0;
  cursor: pointer;
}

.responsavel-checkbox label {
  font-size: 13px;
  color: #495057;
  cursor: pointer;
}

.responsavel-actions {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
}

.move-responsavel {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  color: #6c757d;
  cursor: pointer;
  width: 28px;
  height: 28px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  padding: 0;
}

.move-responsavel:hover:not(:disabled) {
  background-color: #e9ecef;
  color: #495057;
}

.move-responsavel:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.move-responsavel.up:hover:not(:disabled) {
  color: #28a745;
  border-color: #28a745;
}

.move-responsavel.down:hover:not(:disabled) {
  color: #ffc107;
  border-color: #ffc107;
}

.remove-responsavel {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  color: #dc3545;
  cursor: pointer;
  width: 28px;
  height: 28px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  padding: 0;
}

.remove-responsavel:hover {
  background-color: #ffebee;
  border-color: #dc3545;
}

.add-responsavel {
  margin-top: 10px;
  padding: 12px 15px;
  background-color: #f8f9fa;
  border: 1px dashed #adb5bd;
  border-radius: 6px;
  color: #495057;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 14px;
  transition: all 0.2s;
}

.add-responsavel:hover {
  background-color: #fff8f2;
  border-color: #ff6b00;
  color: #ff6b00;
}

/* Ajuste responsivo */
@media (max-width: 1200px) {
  .responsaveis-header {
    grid-template-columns: 2fr 1.5fr 170px 120px;
    gap: 15px;
  }
  
  .responsavel-edit-row {
    grid-template-columns: 2fr 1.5fr 170px 120px;
    gap: 15px;
  }
}

@media (max-width: 992px) {
  .responsaveis-header {
    grid-template-columns: 1.8fr 1.5fr 170px 120px;
    gap: 12px;
  }
  
  .responsavel-edit-row {
    grid-template-columns: 1.8fr 1.5fr 170px 120px;
    gap: 12px;
  }
}

@media (max-width: 768px) {
  .responsavel-edit-row {
    grid-template-columns: 1fr;
    gap: 10px;
    padding: 15px;
  }
  
  .responsaveis-header {
    display: none;
  }
}

.project-order-controls {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: auto;
}

.move-project {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  color: #6c757d;
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.move-project:hover:not(:disabled) {
  background-color: #e9ecef;
  color: #495057;
}

.move-project:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.move-project.up:hover:not(:disabled) {
  color: #28a745;
  border-color: #28a745;
}

.move-project.down:hover:not(:disabled) {
  color: #ffc107;
  border-color: #ffc107;
} 