.agostime-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  background-color: #121212;
  color: #f5f5f5;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.3);
}

.agostime-header {
  display: flex;
  align-items: center;
  background-color: #4caf50;
  background-image: linear-gradient(135deg, #4caf50, #2e7d32);
  color: white;
  padding: 18px 25px;
  border-radius: 0;
  position: relative;
  z-index: 10;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.agostime-header h2 {
  margin: 0 0 0 20px;
  font-size: 1.5em;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.back-button {
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  border: none;
  padding: 10px 24px;
  border-radius: 30px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
}

.back-button:hover {
  background-color: rgba(0, 0, 0, 0.5);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.agostime-content {
  display: flex;
  flex-direction: column;
  background-color: white;
  color: #333;
}

/* Hero Section */
.agostime-hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 20px;
  background: linear-gradient(135deg, #4caf50, #2e7d32);
  background-size: 200% 200%;
  animation: gradientAnimation 15s ease infinite;
  color: white;
  text-align: center;
  position: relative;
  overflow: hidden;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.agostime-hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%23ffffff' fill-opacity='0.05' fill-rule='evenodd'/%3E%3C/svg%3E");
  z-index: 0;
}

.agostime-title-container {
  margin-bottom: 40px;
  position: relative;
  z-index: 1;
  max-width: 600px;
}

.modern-title {
  font-size: 3.5em;
  margin: 0;
  font-weight: 700;
  letter-spacing: 0.5px;
  font-family: 'Montserrat', 'Segoe UI', sans-serif;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  animation: fadeIn 1s ease-out;
}

.agostime-hero h1 {
  font-size: 3.5em;
  margin: 0;
  font-weight: 800;
  letter-spacing: 1px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  animation: fadeIn 1s ease-out;
}

.agostime-subtitle {
  font-size: 1.6em;
  margin-top: 15px;
  font-weight: 300;
  opacity: 0.95;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  animation: fadeIn 1s ease-out 0.3s forwards;
  opacity: 0;
}

.hero-description {
  margin-top: 20px;
  text-align: left;
  animation: fadeIn 1s ease-out 0.6s forwards;
  opacity: 0;
}

.hero-description p {
  margin-bottom: 15px;
  line-height: 1.6;
  font-size: 1.05em;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.hero-description p:last-child {
  margin-bottom: 0;
}

.agostime-image-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  animation: fadeIn 1s ease-out 0.6s forwards;
  opacity: 0;
}

.agostime-image {
  width: 100%;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.4);
  border: 5px solid rgba(255, 255, 255, 0.1);
  transition:
    transform 0.5s ease,
    box-shadow 0.5s ease;
}

.agostime-image:hover {
  transform: scale(1.02);
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
}

/* Features Section */
.agostime-features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  padding: 50px 30px;
  background-color: white;
  position: relative;
}

.feature-card {
  background-color: white;
  padding: 35px 25px;
  border-radius: 12px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
  text-align: center;
  transition: all 0.3s ease;
  border: 1px solid rgba(76, 175, 80, 0.1);
  position: relative;
  overflow: hidden;
}

.feature-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(to right, #4caf50, #8bc34a);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.feature-card:hover::before {
  opacity: 1;
}

.feature-icon {
  margin-bottom: 20px;
  display: inline-block;
  position: relative;
}

.emoji-icon {
  font-size: 3em;
  display: inline-block;
  position: relative;
  z-index: 1;
}

.feature-icon::after {
  content: '';
  position: absolute;
  width: 70px;
  height: 70px;
  background-color: rgba(76, 175, 80, 0.1);
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}

.feature-card h3 {
  color: #4caf50;
  margin: 0 0 15px 0;
  font-size: 1.5em;
  font-weight: 600;
  position: relative;
  display: inline-block;
}

.feature-card h3::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 3px;
  background: linear-gradient(to right, #4caf50, #8bc34a);
  border-radius: 3px;
}

.feature-card p {
  margin: 0;
  line-height: 1.7;
  color: #555;
  font-size: 1.05em;
}

/* Description Section */
.agostime-description {
  padding: 80px 60px;
  background-color: white;
  position: relative;
}

.agostime-description::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.1), transparent);
}

.agostime-description h2 {
  color: #4caf50;
  margin: 0 0 40px 0;
  font-size: 2.2em;
  text-align: center;
  position: relative;
  padding-bottom: 15px;
  font-weight: 700;
}

.agostime-description h2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 4px;
  background: linear-gradient(to right, #4caf50, #8bc34a);
  border-radius: 2px;
}

.agostime-description p {
  margin-bottom: 30px;
  line-height: 1.9;
  font-size: 1.15em;
  color: #333;
  text-align: justify;
  position: relative;
  padding-left: 20px;
  border-left: 3px solid #4caf50;
}

.agostime-description p:last-child {
  margin-bottom: 0;
}

/* Responsive Styles */
@media (min-width: 768px) {
  .agostime-hero {
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    padding: 80px 60px;
  }

  .agostime-title-container {
    flex: 1;
    margin-bottom: 0;
    margin-right: 60px;
  }

  .agostime-image-container {
    flex: 1;
  }

  .agostime-description p {
    font-size: 1.2em;
    padding-left: 25px;
  }

  .hero-description {
    text-align: left;
  }
}

@media (max-width: 767px) {
  .agostime-header h2 {
    font-size: 1.2em;
  }

  .agostime-hero {
    padding: 40px 20px;
  }

  .modern-title,
  .agostime-hero h1 {
    font-size: 2.5em;
  }

  .agostime-subtitle {
    font-size: 1.3em;
  }

  .hero-description p {
    font-size: 0.95em;
  }

  .agostime-features {
    padding: 40px 20px;
  }

  .agostime-description {
    padding: 40px 25px;
  }

  .agostime-description h2 {
    font-size: 1.8em;
  }

  .feature-card {
    padding: 30px 20px;
  }

  .hero-button {
    padding: 10px 20px;
    font-size: 1em;
  }

  .tech-specs {
    padding: 20px;
  }
}

/* Hero Button */
.hero-cta {
  margin-top: 30px;
  animation: fadeIn 1s ease-out 0.9s forwards;
  opacity: 0;
}

.hero-button {
  background: rgba(255, 255, 255, 0.15);
  color: white;
  border: 2px solid rgba(255, 255, 255, 0.3);
  padding: 12px 25px;
  border-radius: 30px;
  font-size: 1.1em;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.download-button {
  background: rgba(255, 255, 255, 0.2);
  border: 2px solid rgba(255, 255, 255, 0.5);
  padding: 12px 30px 12px 45px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.download-button .pulse {
  background-color: #4caf50;
  box-shadow: 0 0 10px rgba(76, 175, 80, 0.7);
}

.download-button .pulse::before {
  background-color: #4caf50;
}

.hero-button:hover {
  background: rgba(255, 255, 255, 0.25);
  transform: translateY(-3px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3);
}

.download-button:hover {
  background: rgba(255, 255, 255, 0.3);
  transform: translateY(-3px) scale(1.05);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.4);
}

.pulse {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.pulse::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.8);
  animation: pulse 2s infinite;
  transform: scale(1);
  top: 0;
  left: 0;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(3);
    opacity: 0;
  }
}

/* Image Overlay */
.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  pointer-events: none;
}

.overlay-dot {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
}

.top-left {
  top: 20px;
  left: 20px;
  animation: blink 3s infinite 0.5s;
}

.top-right {
  top: 20px;
  right: 20px;
  animation: blink 3s infinite 1s;
}

.bottom-left {
  bottom: 20px;
  left: 20px;
  animation: blink 3s infinite 1.5s;
}

.bottom-right {
  bottom: 20px;
  right: 20px;
  animation: blink 3s infinite 2s;
}

@keyframes blink {
  0%,
  100% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
}

/* Feature Icon SVG */
.feature-icon svg {
  width: 50px;
  height: 50px;
  transition: transform 0.3s ease;
}

.feature-card:hover .feature-icon svg {
  transform: scale(1.1);
}

/* Description Grid */
.description-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;
}

.description-content {
  flex: 2;
}

/* Tech Specs */
.tech-specs {
  background-color: #f9f9f9;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  border-left: 4px solid #4caf50;
}

.tech-spec-title {
  font-size: 1.4em;
  font-weight: 600;
  color: #4caf50;
  margin-bottom: 20px;
  text-align: center;
}

.tech-spec-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.tech-spec-list li {
  padding: 12px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tech-spec-list li:last-child {
  border-bottom: none;
}

.tech-spec-list li span {
  font-weight: 600;
  color: #333;
}

@media (min-width: 768px) {
  .description-grid {
    grid-template-columns: 2fr 1fr;
    gap: 50px;
  }
}

@media (max-width: 767px) {
  .hero-button {
    padding: 10px 20px;
    font-size: 1em;
  }

  .tech-specs {
    padding: 20px;
  }
}
