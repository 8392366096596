/* Paleta de cores */
:root {
  --primary-color: #4caf50;
  --secondary-color: #45a049;
  --background-color: #f0f0f5;
  --text-color: #333;
  --shadow-color: rgba(0, 0, 0, 0.1);
  --input-bg-color: #fff;
  --button-color: #ffffff;
  --button-close-color: #ffcc99; /* Laranja claro para o botão de fechar */
}

/* Ajustes globais para evitar barras de rolagem horizontais */
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

body {
  font-family: 'Open Sans', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  max-width: 100%;
  text-align: center;
  background-color: var(--background-color);
  overflow-x: hidden;
  box-sizing: border-box;
  padding: 20px;
}

/* Ajuste para o tamanho da logo e espaçamento */
.logo {
  max-width: 80%;
  margin-bottom: 40px;
}

/* Animação de fade */
.fade-effect {
  opacity: 0;
  animation: fadeIn 1s forwards ease-in-out;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Estilo para as mensagens no chatbox */
.fade-effect-left {
  animation: slideInLeft 1.5s ease-in-out;
}

.fade-effect-right {
  animation: slideInRight 1.5s ease-in-out;
}

/* Input e botões com bordas arredondadas e sombras */
input,
select,
button,
textarea {
  padding: 10px;
  margin: 10px;
  width: 300px;
  font-size: 16px;
  border-radius: 10px;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 6px var(--shadow-color);
  background-color: var(--input-bg-color);
}

textarea {
  height: 150px;
  resize: none;
}

button {
  background-color: var(--primary-color);
  color: var(--button-color);
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition:
    background-color 0.3s ease-in-out,
    transform 0.2s;
}

button:hover {
  background-color: var(--secondary-color);
  transform: scale(1.05);
}

h1 {
  font-size: 2.5rem;
  color: var(--text-color);
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin: 10px 0;
  font-size: 18px;
  cursor: pointer;
  padding: 8px;
  border-radius: 5px;
  background-color: var(--input-bg-color);
  box-shadow: 0px 4px 6px var(--shadow-color);
  transition: background-color 0.3s ease;
}

li:hover {
  background-color: var(--hover-color);
  color: white;
}

h2 {
  margin-top: 20px;
  color: var(--text-color);
}

/* Efeito de hover para caixas de input */
input:focus,
textarea:focus {
  outline: none;
  box-shadow:
    0px 6px 8px var(--shadow-color),
    0px 0px 10px var(--primary-color);
}

/* Estilo atualizado para o chatbox */
.chatbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  max-height: 70vh;
  padding: 20px;
  background: linear-gradient(135deg, #e0f7fa, #f1f8e9);
  border-radius: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

.chatbox-header button:hover {
  background-color: #ffa366;
}

.chat-history {
  width: 100%;
  height: 50vh;
  overflow-y: auto;
  background-color: var(--input-bg-color);
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 4px 6px var(--shadow-color);
}

.chat-message {
  margin: 8px 0;
  animation: slideIn 0.5s ease-in-out;
}

.chat-message p {
  margin: 0;
}

.chat-message.user-message {
  text-align: right;
  color: #4caf50;
}

.chat-message.bot-message {
  text-align: left;
  color: #3e8e41;
}

/* Estilo para o rodapé com o texto de crédito */
.footer {
  position: fixed;
  bottom: 10px;
  right: 10px;
  font-size: 12px;
  color: var(--text-color);
}

/* Estilo para o botão "Fechar" no ticket */
.close-button {
  background-color: var(--button-close-color);
  color: var(--text-color);
  border: none;
  cursor: pointer;
  font-size: 14px;
  padding: 6px 12px;
  border-radius: 8px;
  margin-top: 10px;
  display: block; /* Faz o botão ocupar uma linha inteira */
  margin-left: auto;
  margin-right: auto; /* Centraliza o botão */
  width: 150px; /* Ajusta a largura */
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: #ffa366; /* Cor um pouco mais escura no hover */
}

/* Estilo exclusivo para o botão "Falar com a IA" na página de detalhes de procedimentos */
.details #chatButton {
  background-color: #ddd; /* Cor mais discreta */
  color: #666;
  font-size: 14px;
  border: 1px solid #ccc;
  padding: 8px 16px;
  border-radius: 8px;
  margin-top: 20px;
}

.details #chatButton:hover {
  background-color: #ccc; /* Cor do hover também mais discreta */
  color: #444;
}

/* Estilo para a página de detalhes de procedimentos tipo ticket */
.details {
  background-color: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 30px;
  margin-top: 20px;
  width: 80%;
  text-align: left;
  animation: fadeIn 0.7s ease-in-out;
}

.details h2 {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 20px;
  text-align: center;
}

.details .titulo {
  font-size: 1.6rem;
  color: var(--text-color);
  margin-bottom: 20px;
  text-align: center;
}

.details .resolucao {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 20px;
  white-space: pre-wrap; /* Permitir quebra de linha no texto */
}

.details .botao-ia {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* Melhorar a sombra e o espaçamento entre as mensagens */
.chat-message p {
  margin-bottom: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
}

/* Adicionar uma linha temporária como animação de digitação */
.dots {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 3px;
  border-radius: 50%;
  background-color: #333;
  animation: blink 1.2s infinite;
}

@keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

/* Efeito de fade-out para quando o chat fecha */
.fade-out {
  opacity: 0;
  transition: opacity 2s ease-in-out;
}

/* Efeito de fade para o chatbox */
.fade-effect {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

/* Animação de fade */
.fade-effect {
  opacity: 0;
  animation: fadeIn 1.5s forwards ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-out {
  opacity: 1;
  animation: fadeOut 1s forwards ease-in-out;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

li.fade-in {
  opacity: 0;
  animation: fadeIn 1s forwards ease-in-out;
}

.chatbox.fade-out {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

/* Estilo para os botões de like e dislike */
.like-dislike {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.like-button,
.dislike-button {
  background-color: #f0f0f5;
  color: #333;
  border: 1px solid #ccc;
  padding: 10px;
  margin: 0 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.2s ease;
}

.like-button:hover,
.dislike-button:hover {
  background-color: #ddd;
}

.like-button:disabled,
.dislike-button:disabled {
  background-color: #eee;
  cursor: not-allowed;
}

.chat-message {
  margin: 10px 0;
  padding: 10px;
  border-radius: 10px;
  line-height: 1.5;
  white-space: pre-wrap; /* Quebra de linha nas mensagens longas */
}

/* Mensagens do Usuário */
.chat-message.user-message {
  background-color: #e0f7fa; /* Fundo azul claro */
  color: #333;
  text-align: right;
}

/* Mensagens da IA */
.chat-message.bot-message {
  background-color: #f1f8e9; /* Fundo verde claro */
  color: #444;
  text-align: left;
}

/* Efeito de fade-in para o texto "Desenvolvido por Fabio Carvalho" */
.fade-effect3 {
  opacity: 0;
  animation: fadeIn 10s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Ajuste geral para centralizar elementos na tela */
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0 15px;
  text-align: center;
}

/* Manter o tamanho original da logo */
.logo {
  max-width: 80%; /* Manter o tamanho original */
  margin-bottom: 20px; /* Espaço entre a logo e o campo de pesquisa */
}

/* Ajuste para o campo de pesquisa e botão no celular */
@media (max-width: 768px) {
  input,
  button {
    width: 90%;
    margin: 5px 0; /* Reduz o espaçamento entre os elementos */
  }

  /* Espaço mais próximo entre o campo de pesquisa e o botão */
  input {
    margin-bottom: 5px; /* Reduzido para aproximar do botão */
  }

  button {
    margin-top: 5px; /* Reduzido para menor espaço */
  }

  .chatbox {
    width: 90%;
    max-width: 100%;
    padding: 15px;
  }

  .chatbox-header {
    width: 100%;
  }

  .chat-history {
    height: 40vh;
  }

  .player-container {
    margin-top: 10px;
  }
}

/* CSS para animar o formulário de login com fade-in */
.login-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  width: 320px;
  z-index: 1000;
  animation: fadeInForm 0.3s ease-in-out;
  text-align: center;
}

.login-form input {
  width: 100%;
  padding: 12px 15px;
  margin-bottom: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 14px;
  transition:
    border-color 0.3s,
    box-shadow 0.3s;
  box-sizing: border-box;
}

.login-form input:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
  outline: none;
}

.login-form button {
  width: 100%;
  padding: 12px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-form button:hover {
  background-color: var(--secondary-color);
}

.login-form::before {
  content: '';
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 0 10px 10px 10px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}

.login-text {
  position: absolute;
  top: 15px;
  left: 15px;
  color: #4caf50;
  font-weight: 600;
  cursor: pointer;
  padding: 8px 15px;
  border-radius: 20px;
  transition: all 0.3s;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(76, 175, 80, 0.3);
}

.login-text:hover {
  background-color: #4caf50;
  color: white;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

@keyframes fadeInForm {
  from {
    opacity: 0;
    transform: translate(-50%, -60%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

/* Estilo para os botões do menu de administração */
.admin-menu .button-general {
  width: 80%;
  padding: 12px 0;
  background-color: #4caf50; /* Verde para o fundo */
  color: #fff; /* Texto branco */
  font-size: 1em;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: none !important; /* Remove qualquer sombra */
  outline: none !important; /* Remove o contorno */
  transition: background-color 0.2s ease; /* Transição suave para o fundo */
  text-align: center;
  margin: 5px 0;
}

/* Remove qualquer sombra ou contorno no hover */
.admin-menu .button-general:hover,
.admin-menu .button-general:focus,
.admin-menu .button-general:active {
  background-color: #388e3c; /* Verde mais escuro no hover */
  color: #fff; /* Mantém o texto branco */
  box-shadow: none !important; /* Remove qualquer sombra */
  outline: none !important; /* Remove o contorno */
}

/* Remove qualquer sombra ou contorno no hover */
.admin-menu .button-general:hover,
.admin-menu .button-general:focus,
.admin-menu .button-general:active {
  background-color: #388e3c; /* Verde mais escuro no hover */
  color: #fff; /* Mantém o texto branco */
  box-shadow: none !important; /* Remove qualquer sombra */
  outline: none !important; /* Remove o contorno */
}

.estatisticas-container {
  margin: 20px auto;
  padding: 20px;
  max-width: 800px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.estatisticas-container h2 {
  margin-bottom: 20px;
  color: #4caf50; /* Verde para o título */
}

.estatisticas-container p {
  font-size: 16px;
  margin: 10px 0;
}

/* Ajuste para a logo na vitrine */
.logo-pequena {
  position: absolute;
  top: 10px;
  right: 20px;
  width: 100px; /* Define o tamanho menor */
  transition: transform 0.3s ease-in-out;
}

.logo-pequena:hover {
  transform: scale(1.1); /* Pequena animação ao passar o mouse */
}

/* Esconde a barra de pesquisa e o botão GPTMix na vitrine */
.vitrine-container .search-bar,
.vitrine-container #chatButton {
  display: none;
}

/* Estilização da vitrine */
.vitrine-container {
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--background-color);
  overflow-x: hidden;
  box-sizing: border-box;
  padding-bottom: 100px;
}

.logo-container {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10;
}

.logo-reduzida {
  width: 120px;
  transition: transform 0.3s ease;
}

.apps-grid {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
  margin-bottom: 40px;
  flex-wrap: wrap;
  width: 100%;
  max-width: 900px;
  box-sizing: border-box;
}

.app-card {
  background-color: #ffffff;
  padding: 25px;
  width: 180px;
  height: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
  cursor: pointer;
  margin-bottom: 20px;
}

.app-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

.app-card h3 {
  margin: 0;
  color: var(--primary-color);
  font-size: 18px;
  margin-bottom: 5px;
}

.app-card p {
  font-size: 14px;
  color: #666;
  margin: 5px 0 0 0;
  text-align: center;
}

.vitrine-footer {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 100;
  max-width: 250px;
}

.vitrine-footer .player-container {
  margin-bottom: 10px;
  width: 100%;
}

.vitrine-footer .player-container audio {
  width: 100%;
  border-radius: 8px;
}

.vitrine-footer p {
  font-size: 12px;
  color: #666;
  margin: 5px 0 0 0;
}

.logout-button {
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 8px 15px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  z-index: 100;
  width: auto;
}

.logout-button:hover {
  background-color: #d32f2f;
}

/* Animações */
.fade-in {
  animation: fadeIn 0.5s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Botão de voltar no chat */
.back-button {
  background-color: #2196f3;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 15px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  z-index: 1001;
}

.back-button:hover {
  background-color: #0b7dda;
}

.chatbox-header {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.app-icon {
  width: 60px;
  height: 60px;
  margin-bottom: 15px;
  object-fit: contain;
}

/* Overlay para o formulário de login */
.login-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  animation: fadeIn 0.3s ease-in-out;
}

/* Ajustes responsivos para diferentes tamanhos de tela */
@media (max-width: 768px) {
  .apps-grid {
    gap: 15px;
    padding: 0 10px;
  }

  .app-card {
    width: 150px;
    height: 150px;
    padding: 15px;
  }

  .logo-reduzida {
    width: 80px;
  }

  .vitrine-footer {
    position: relative;
    bottom: auto;
    right: auto;
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .vitrine-footer .player-container audio {
    width: 200px;
  }

  .logout-button {
    top: 10px;
    left: 10px;
    padding: 5px 10px;
    font-size: 14px;
  }
}

/* Ajustes para telas muito pequenas */
@media (max-width: 480px) {
  .app-card {
    width: 130px;
    height: 130px;
    padding: 10px;
  }

  .app-icon {
    width: 40px;
    height: 40px;
  }

  .app-card h3 {
    font-size: 16px;
  }

  .app-card p {
    font-size: 12px;
  }
}

/* Estilo para o container de aplicativos */
.app-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: #121212;
  overflow: hidden;
}

/* Estilo para o botão de voltar */
.back-button {
  background-color: #2196f3;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 15px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  z-index: 1001;
}

.back-button:hover {
  background-color: #0b7dda;
}

/* Estilos para o iframe do RBI */
.iframe-header {
  display: flex;
  align-items: center;
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border-radius: 8px 8px 0 0;
  margin-bottom: 5px;
}

.iframe-header h2 {
  margin: 0 0 0 20px;
  font-size: 1.5em;
}

.rbi-iframe {
  width: 100%;
  height: calc(100vh - 80px);
  border: none;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Ajustes responsivos para o iframe */
@media (max-width: 768px) {
  .rbi-iframe {
    height: calc(100vh - 120px);
  }

  .iframe-header h2 {
    font-size: 1.2em;
  }
}
